.custom-input-container > div {
  width: 6rem;
}
.custom-input-container-auto-complete > div {
  width: 12rem;
}
.ag-theme-alpine {
    --ag-header-height: 30px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: black;
    --ag-header-cell-hover-background-color: rgb(80, 40, 140);
    --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}
.ag-theme-alpine .ag-header {
    font-family: bold;
}
.ag-theme-alpine .ag-header-group-cell {
font-weight: bold;
    font-size: 22px;
}
.ag-theme-alpine .ag-header-cell {
    font-size: 18px;
    font-weight: bold;
}