h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
